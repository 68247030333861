<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
	title: String,
	description: String,
	links: Object,
	contractLinks: Array,
})

const isDescriptionShow = ref(false)

const toggleDescription = () => {
	isDescriptionShow.value = !isDescriptionShow.value
}
</script>

<template>
	<div class="accordion">
		<div class="accordion__title" @click="toggleDescription">
			<p class="accordion__title-text">{{ props.title }}</p>
			<span
				class="accordion__title-icon"
				:class="{ 'accordion__title-icon_active': isDescriptionShow }"
			>
				<svg
					width="12"
					height="7"
					viewBox="0 0 12 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						class="arrow-path"
						d="M11.0002 1L6.00024 6L1.00024 0.999999"
						stroke="#828282"
						stroke-linecap="round"
					/>
				</svg>
			</span>
		</div>
		<div
			class="accordion__description"
			:class="{ 'accordion__description-active': isDescriptionShow }"
		>
			<div class="accordion__description-text">
				<p v-if="props.description" v-html="props.description"></p>

				<div v-if="props.links" class="accordion__links">
					<a
						v-for="(url, name) in props.links"
						:key="name"
						class="accordion__link"
						:href="url"
						target="_blank"
					>
						📄 {{ name }}
					</a>
				</div>

				<div v-if="props.contractLinks" class="accordion__contract">
					<a
						v-for="contract in props.contractLinks"
						:key="contract.name"
						:href="contract.url"
						class="accordion__link"
						target="_blank"
					>
						📄 {{ contract.name }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.accordion {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 30px;
	border-radius: 10px;
	background: #f9f9f9;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
  font-family: Montserrat;

	&__title {
		width: 100%;
		border-radius: 8px;
		background-color: white;
		cursor: pointer;

		padding: 22px 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: background 0.3s ease-in-out;

		&:hover {
			background: #f0f0f0;
		}

		&-text {
			font-weight: 600;
			font-size: 16px;
			color: #000;
			margin: 0;
		}

		&-icon {
			width: 10px;
			transition: transform 0.3s ease-in-out;

			&_active {
				transform: rotate(180deg);
			}

			svg {
				width: 10px;
			}
		}
	}

	&__description {
		display: grid;
		grid-template-rows: 0fr;
		transition: grid-template-rows 500ms;

		&-text {
			overflow: hidden;
			background-color: white;
			color: #444;
			font-size: 16px;
			line-height: 1.5;
			white-space: pre-line;
			padding: 0px 20px;

			p {
				margin: 0;
				padding: 22px 20px;
			}

			b {
				color: #333;
				font-weight: bold;
			}

			br {
				content: '';
				display: block;
				margin-bottom: 10px;
			}
		}

		&-active {
			grid-template-rows: 1fr;
		}
	}

	&__links,
	&__contract {
		padding: 22px 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__link {
		color: #333;
		text-decoration: none;
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		transition: color 0.2s linear;

		&:hover {
			color: blue;
		}
	}
}
</style>

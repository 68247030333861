export const informationList = [
	{
		title: 'Основные сведения и документы',
		description: `
      Автономная некоммерческая организация "Спортивно-оздоровительный Центр "Берёзка" (Министерство спорта Саратовской области)

    Юр. адрес: 410054, Саратовская область, г. Саратов, ул.Новоузенская, зд.250, стр.1
Адрес местонахождения: 410054, Саратовская область, г. Саратов, ул. Новоузенская, зд.250, стр.1
Почтовый адрес:410054, Саратовская область, г. Саратов, Большая Кумысная поляна, а/я 1344
Телефон: 8(8452) 56-31-07
ИНН/КПП 6455072031/645101001
ОГРН 1196451005684; ОКПО: 36517428
р/с 40703810614240000002 Филиал «Центральный» Банка ВТБ (ПАО)
к/с № 30101810145250000411
БИК 044525411

Директор: Крук Илья Леонидович
Исполнительный директор: Амхир Борис Константинович
Главный бухгалтер: Артамонова Ирэна Алексеевна

Номер телефона
Администрация: +7(8452) 56-31-07
Отдел продаж путевок: +7(937) 225-62-44
Отдел продаж путевок: +7(8452) 25-62-44
Бухгалтер: +7 (927) 165-46-78
Отдел кадров: +7 (937) 224-56-63

Электронная почта
Администрация: admin@berezka64.ru
Отдел продаж путевок: sales-b@berezka64.ru
Бухгалтерия: nazarova.elena@berezka64.ru
Онлайн-проекты/реклама: project@berezka64.ru
Отдел кадров: otdel.kadrov@berezka64.ru

Время работы отдела продаж путёвок: ПН - ПТ с 10:00 до 16:00`,
	},
	// {
	// 	title: 'Структура и органы управления Организации',
	// 	description:
	// 		'Берёзка принимает ребят от 6 до 17 лет. В лагере в зависимости от заполняемости смены формируются отряды по 20-25 детей в каждом. Дети распределяются в отряды по возрастам с разницей 1-2 года. Дети проживают в отапливаемых деревянных корпусах «Капелька»,«Улыбка»,«Чебурашка» и в кирпичных корпусах «Лесной»,«Радуга» и «Дружба». Во всех корпусах оборудованы комфортабельные санузлы, душевые с горячей водой и питьевые фонтанчики. Ребят расселяют в комнаты по 4-5 человек, в зависимости от размеров помещений. Комнаты оборудованы односпальными кроватями, индивидуальными тумбочками, и платяным шкафом. В каждом корпусе есть свои общие залы с диванами и телевизорами. В каждом отряде работают по 2-3 вожатых.',
	// },
	// {
	// 	title: 'Документы',
	// 	description: `Для заезда в лагерь необходимо предоставить следующие документы и справки:

	//    путевку*;
	//    медицинскую справку (форма 079/у), полученную не ранее, чем за 3 дня до заезда**;
	//    документ об отсутствии контактов с COVID-19 и эпидокружении , полученный не ранее, чем за 3 дня до заезда***;
	//    копию свидетельства о рождении или паспорта;
	//    копию страхового медицинского полиса.
	//    распечатанное и заполненное Медсогласие
	//    прием в лагерь детей, которые не были вакцинированы согласно Национальному календарю профилактических прививок для детей, возможен только при предъявлении оформленного родителями официального документа-отказа, заверенного подписью уполномоченного врача и официальной печатью поликлиники. При отсутствии данного документа лагерь не имеет права принимать невакцинированного ребенка.
	//    Без указанных документов ребенок в СОЦ не допускается.

	//    По возникшим медицинским вопросам и справкам вы можете получить информацию у нашего медицинского сотрудника Врача педиатра +7 (986) 988-04-33
	//    `,
	// },
	{
		title: 'Деятельность',
		description: `В летний период АНО СОЦ Берёзка принимает ребят от 6 до 17 лет на спортивно-оздоровительный отдых в летний лагерь. За лето проводится 5 смен от 14 дней до 21 дня по 360 детей в каждой смене. Реализация путевок в летний лагерь осуществляется через сайт АНО СОЦ Березка <a href="https://www.berezka64.ru/" target="_blank">www.berezka64.ru</a> и через офис продаж АНО СОЦ Березка.
      
            В Зимний период в Березке организован зимний оздоровительный досуг для семей и любителей зимнего спорта. Работают лыжный прокат, каток с прокатом спортивного инвентаря и кафе. <a href="https://www.berezka64.ru/relaxation" target="_blank">Семейный отдых</a>
      `,
	},
	{
		title: 'Руководство. Педагогический и вожатский состав',
		description: `<b>Руководство:</b>
      Крук Илья Леонидович – директор лагеря (+7(8452) 56-31-07, <a href='mailto:admin@berezka64.ru'>admin@berezka64.ru</a>)
      Амхир Борис Константинович – исполнительный директор (+7(8452) 56-31-07, <a href='mailto:admin@berezka64.ru'>admin@berezka64.ru</a>)
      Стрелкова Ольга Сергеевна – заместитель директора по хозяйственно - административным вопросам (+7(987) 809-07-45, <a href='mailto:admin@berezka64.ru'>admin@berezka64.ru</a>)
      Горобец Елена Ивановна – заместитель директора по воспитательной работе (+7(917) 202-33-43, <a href='mailto:admin@berezka64.ru'>admin@berezka64.ru</a>)

      <b>Педагогический и вожатский состав:</b>
      Горобец Елена Ивановна - заместитель директора по воспитательной работе АНО СОЦ «Берёзка».
      Учитель музыки ГАОУ СО «Гимназия #8».
      ⁠Образование: 1978-1982гг - Саратовское педагогическое училище
      Направление: Музыкальное воспитание дошкольников и школ ников начальных классов.
      Повышение квалификации: 1985г: педагог- организатор
      1990г: учитель музыки начальных классов.
      1995г: учительмузыки среднего звена общеобразовательных учреждений.
      2000г: присвоена высшая квалификационная категория ( педагог- организатор)
      2005: присвоена высшая квалификационная категория учителя музыки.
      Последнее место работы ГАОУ СО «Гимназия #8»- с 1995 по настоящее время, учитель музыки.
      Педагогический стаж работы 43 года.
      Квалификация: Педагог- наставник, учитель- тьютер, педагог- организатор.

      Иванова Дарья Денисовна - старший вожатый.
      Учитель музыки ГАОУ СО «Гимназия #8».
      ⁠Образование: 2019-2023гг - Поволжский институт управления имени П.А. Столыпина (филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации), Саратов.
      Направление: Музыкальное воспитание дошкольников и школьников начальных классов.
      Факультет: Политико-правовое управления
      Направление подготовки: Зарубежное регионоведение.
      Повышение квалификации: 2024г: Специалист по развитию личной и профессиональной эффективности
      Квалификация: Психолог в социальной сфере
      `,
	},
	{
		title: 'Вакансии',
		description: `Каждый сезон, начиная с 1 апреля мы ведем набор вожатых по линии студенческих отрядов педагогической направленности. В начале мая начинаются курсы подготовки, по завершении которых вожатый получает Сертификат об окончании курсов.
      	Набором, обучением и подготовкой вожатых занимаются непосредственно Заместитель Директора по воспитательной работе Горобец Елена Ивановна и Директор лагеря Амхир Борис Константинович.

     	 <b><a href="https://www.berezka64.ru/vacancies" target="_blank">Вакансии</a></b>`,
	},
	{
		title: 'Материально-техническое обеспечение и оснащенность Организации',
		description: `
      <li>Отапливаемые деревянные домики со всеми удобствами внутри</li>
      <li>Отапливаемые кирпичные двухэтажные корпуса со всеми удобствами внутри</li>
      <li>Медпункт</li>
      <li>Столовый комплекс кондиционированный на 300 мест и на 120 мест, кухня с современным оборудованием</li>
      <li>Бассейн открытый 25X10 с аквагоркой и 2-мя каскадами, с подогревом.</li>
      <li>Стадион с футбольным полем 60х30 м., круговые беговые дорожки с резиновым покрытием, трибуна на 800 мест</li>
      <li>2 волейбольных площадки</li>
      <li>Снайпер площадка</li>
      <li>Баскетбольная площадка</li>
      <li>Мини футбол на траве</li>
      <li>Пожарная полоса препятствий</li>
      <li>Открытая тренажерная площадка</li>
      <li>Видеосалон/караоке кондиционированное помещение</li>
      <li>Веселая площадка - крытые 3 трибуны на 600 мест с цветными пластиковыми креслами, крытая ракушка – эстрада, оснащенная современной акустикой и светом</li>
      <li>Игротека с настольными играми, видео-приставками, аэро-хоккеем и настольным футболом</li>
      <li>Библиотека укомплектована самой разнообразной литературой для всех возрастных категорий детей</li>
      <li>Уличный игровой комплекс для малышей</li>
      <li>Администрация</li>
      <li>Зимний корпус</li>
      <li>Общежития сотрудников</li>
      <li>Складские помещения</li>
      <li>Домик охраны</li>
      <li>Артезианские скважины</li>
      <li>3 газовых котельных</li>

      Условия проживания детей:
      В лагере в зависимости от заполняемости смены формируются отряды по 20-25 детей в каждом. Дети распределяются в отряды по возрастам с разницей 1-2 года.
      Проживание в отапливаемых деревянных корпусах «Капелька», «Улыбка», «Чебурашка» и в кирпичных корпусах «Лесной»,«Радуга» и «Дружба».
      Во всех корпусах оборудованы комфортабельные санузлы, душевые с горячей водой и питьевые фонтанчики.
      Ребят расселяют в комнаты по 4-5 человек, в зависимости от размеров помещений. Комнаты оборудованы односпальными кроватями, индивидуальными тумбочками, и платяным шкафом.
      В каждом корпусе есть свои общие залы с диванами и телевизорами.
      В каждом отряде работают по 2-3 вожатых.

      Условия питания детей:
      В лагере для детей организовано 5-ти разовое питание: Завтрак, Обед, Полдник, Ужин и Сонник.

      Меню тщательно составляется с учетом всех норм и требований СанПина.

      Ежедневно дети получают необходимое количество мяса, овощей, круп, молочной продукции, фруктов, сладостей, соков, компотов. Регулярно в рационе питания присутствует рыба, птица, макаронные изделия, сезонные ягоды.

      Березка всегда славилась своей вкусной кухней и легендарной собственной выпечкой. <a href="/parents#day-menu">Примерное меню на день</a>
    `,
	},
	{
		title: 'Услуги, в том числе платные, предоставляемые Организацией',
		description: `
      <b>Продажа летних путевок <a href="https://www.berezka64.ru/" target="_blank">ЛЕТО_2025</a></b> 
1 СМЕНА   03.06 – 16.06  (14 ДНЕЙ)  45000 РУБ. (1 день пребывания 3214 руб.)
2 СМЕНА   19.06 – 02.07  (14 ДНЕЙ)  45000 РУБ. (1 день пребывания 3214 руб.)
3 СМЕНА   05.07 – 18.07  (14 ДНЕЙ)  45000 РУБ. (1 день пребывания 3214 руб.)
4 СМЕНА   21.07 – 03.08  (14 ДНЕЙ)  45000 РУБ. (1 день пребывания 3214 руб.)
5 СМЕНА   06.08 – 26.08  (21 ДЕНЬ)   55000 РУБ. (1 день пребывания 2619 руб.)
<b>Для заезда в лагерь необходимо предоставить следующие <a href="https://www.berezka64.ru/parents#docs" target=_blank">документы и справки</a>:</b>
путевку*;
медицинскую справку (форма 079/у), полученную не ранее, чем за 3 дня до заезда;
документ об отсутствии контактов с COVID-19 и эпидокружении , полученный не ранее, чем за 3 дня до заезда***;
копию свидетельства о рождении или паспорта;
копию страхового медицинского полиса.
распечатанное и заполненное Медсогласие
прием в лагерь детей, которые не были вакцинированы согласно Национальному календарю профилактических прививок для детей, возможен только при предъявлении оформленного родителями официального документа-отказа, заверенного подписью уполномоченного врача и официальной печатью поликлиники. При отсутствии данного документа лагерь не имеет права принимать невакцинированного ребенка.
Без указанных документов ребенок в СОЦ не допускается.

По возникшим медицинским вопросам и справкам вы можете получить информацию у нашего медицинского сотрудника Врача педиатра +7 (986) 988-04-33

* После полной оплаты путевки на ваш электронный адрес, указанный при регистрации в личном кабинете, придет письмо с электронной путевкой и чеком. Данную путевку необходимо распечатать и предоставить на заезде с остальным пакетом документов на ребенка.</b>

 Форма 079/У запрашивается у участкового педиатра в клинике (Медицинская справка на ребенка, отъезжающего в санаторный оздоровительный лагерь (форма 079/у) - это обязательный медицинский документ ребенка, который планирует посетить детский лагерь с целью отдыха. Справка заполняется медицинским работником амбулаторно-поликлинического учреждения или школы. Назначение справки - обеспечить медицинский персонал лагеря всей необходимой информацией о состоянии здоровья ребенка, прибывшего на отдых и оздоровление (о хронических заболеваниях, об АКДС, перенесенных инфекционных заболеваниях, физкультурной группе).

Эти сведения дадут возможность работникам лагеря обеспечить правильный режим отдыха и оздоровительных мероприятий, подобрать индивидуальную физическую нагрузку для ребенка. Справка заполняется после медицинского осмотра ребенка. Медицинский работник поликлиники (школы) вносит записи на лицевой стороне справки. Обратная ее часть заполняется уже непосредственно медперсоналом оздоровительного заведения).


*** Справка об эпидокружении и отсутствии контакта с COVID-зараженными может быть выдана отдельно или вписана в форму 079/У.

<b>Компенсации</b>
Вы можете самостоятельно распечатать ДОГОВОР ОКАЗАНИЯ УСЛУГ в 2-х экземплярах, заполнить и привезти на подпись в рабочее время отдела продаж;
Распечатайте и заполните СПРАВКУ О ПРЕБЫВАНИИ РЕБЕНКА В ЛАГЕРЕ. Дата окончания смены является датой выдачи справки. Поставить печать на справку вы можете в рабочее время отдела продаж, не ранее 2-х дней до окончания смены;
Отрывным талоном является часть путевки, которая остается у вас после регистрации ребенка на заезде в лагерь;
Подтверждением оплаты является чек об оплате, который приходит на вашу электронную почту сразу после оплаты путевки;
Санитарно-эпидемиологическое заключение вы можете СКАЧАТЬ и распечатать;

Подписать в лагере все необходимые документы Вы сможете по окончании смены, предварительно позвонив по номеру телефона отдела продаж +7(8452) 25-62-44.

<b>Информация по компенсации за путевку в лагерь от правительства Саратовской области.</b>
Компенсация предоставляется МНОГОДЕТНЫМ СЕМЬЯМ, независимо от финансового положения семьи.
Компенсируется часть путевки на каждого ребенка в семье, но только по 1 путевке в год за летний период 2024 года.

Компенсация в размере 50% от стоимости из расчета 1248 руб/сутки (по установленной среднесуточной расценке Министерства труда и соцразвития).
ИТОГО:
14 дней - 8736 руб. компенсации после пребывания в лагере;
21 день - 13104 руб. компенсации после пребывания в лагере
Уважаемые родители, уточняйте всю информацию по компенсациям только по телефонам горячей линии Минсоцразвития 98-26-54, 65-33-20

<b>Условия заезда и отъезда детей в смену</b>
После покупки путевки Вы можете заранее забронировать место в отряде для своего ребёнка или группы детей, за 2-3 дня до заезда по номеру телефона +7(917) 202-33-43 Горобец Елена Ивановна 
<b>Заезд в лагерь</b> происходит организованно с 7:00 до 12:00 в первый день смены. Вас встретят у главных ворот сотрудники лагеря и помогут пройти процедуру регистрации и медосмотр ребенка.Время заезда регламентировано, т.к. до обеда необходимо сформировать заявку с точным количеством детей на питание и размещение в отрядах. Для комфортной психологической адаптации ребенка привозите его в строго установленное время. Для ускорения прохождения процедуры регистрации при заезде, заранее распечатайте и подпишите Медсогласие. Предъявите подписанный документ на заезде. <b>Отъезд</b> осуществляется в последний день смены до 13:00. <b>Заезд и отъезд</b> осуществляются силами родителей или опекунов.

<b>Дети при себе должны иметь с обеспечением на всю смену:</b>
сменное нижнее белье, носки, одежду и обувь
теплые вещи, куртка, плащ или зонт на случай плохой погоды
спортивная одежда и спортивная обувь
- обувь закрытая/непромокаемая/тёплая
- сменная комнатная обувь, резиновые тапочки
- головной убор от солнца/ветра
- нарядная одежда для праздничных мероприятий
- предметы личной гигиены: зубная щетка в футляре, паста, мыло в закрывающейся мыльнице, шампунь, мочалка, расческа, влажные салфетки
- детский защитный крем от солнца, на который у ребенка нет аллергии (в летний сезон)
- шорты, футболки и лёгкая летняя обувь (в летний сезон)
- купальник/плавки, принадлежности для купания, полотенце для бассейна (в летний сезон)
- средство от комаров, на которое у ребенка нет аллергии (в летний сезон)
- пакет для грязной одежды

Убедительно просим вас подписать ВСЕ вещи и обувь ребенка несмываемым текстильным маркером или сделать нашивки с ФИО ребенка перед отправкой в лагерь!
Также необходимо составить список ВСЕХ вещей (одежда, обувь, косметические принадлежности, аксессуары, книги и т.д.), которые вы даете ему с собой в поездку, и положить его в чемодан вместе с вещами.
Будьте предусмотрительнее! Этим вы сэкономите время и нервы себе и своему ребенку.


Что НЕ стоит брать ребенку в детский СОЦ «Березка»:

Любые вещи, которые было бы жаль поломать или потерять
Это ювелирные изделия, семейные ценности, мобильный телефон, планшет, электронные игры. Такие вещи часто бьются или теряются. Не рекомендуется давать ребенку с собой дорогие одежду, косметику, обувь и украшения. Мы настоятельно рекомендуем родителям не давать детям с собой дорогостоящие гаджеты.

Категорически запрещено хранить любые лекарства в отряде!
Индивидуальные, необходимые для регулярного приема лекарства, передаются законным представителем лично врачу лагеря вместе с инструкцией по применению и дозировке и хранятся только в медпункте.

<b>Вне летнего сезона на территории АНО СОЦ Березка работают прокат велосипедов, прокат лыж, каток с прокатом инвентаря и кафе. <a href="https://www.berezka64.ru/relaxation" target="_blank">Семейный отдых</a></b>
      `,
	},
]

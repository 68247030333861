<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Accordion from '../components/elements/Accordion.vue'
import { informationList } from '../../constants/information'

const store = useStore()

const linksFooter = computed(() => store.getters['links/GET_LINKS_FOOTER'])

const contractLinks = [
	{
		name: 'Договор',
		url: 'https://drive.google.com/file/d/1ZuTsGflpKmwtLcToS1VwiWDFxrxNYNMc/view?usp=sharing',
	},
	{
		name: 'Медсогласие',
		url: 'https://drive.google.com/file/d/1QFXs9NEDjusVu-zRT9vDTkFSyiWDSE3y/view?usp=sharing',
	},
]

const accordionItems = computed(() =>
	informationList.map(item => {
		if (item.title === 'Основные сведения и документы') {
			return {
				...item,
				links: Object.fromEntries(
					Object.entries(linksFooter.value || {}).filter(([name]) =>
						[
							'Устав организации',
							'Приказ о создании АНО СОЦ Берёзка',
							'Свидетельство ОГРН',
							'Сан-эпид.заключение 2024',
						].includes(name)
					)
				),
			}
		}
		if (
			item.title ===
			'Материально-техническое обеспечение и оснащенность Организации'
		) {
			return {
				...item,
				links: Object.fromEntries(
					Object.entries(linksFooter.value || {}).filter(([name]) =>
						['Паспорта доступности'].includes(name)
					)
				),
			}
		}
		// if (item.title === 'Документы') {
		// 	return { ...item, links: linksFooter.value }
		// }
		if (
			item.title === 'Услуги, в том числе платные, предоставляемые Организацией'
		) {
			return { ...item, contractLinks }
		}
		return item
	})
)

onMounted(() => {
	store.dispatch('links/fetchLinks')
})
</script>

<template>
	<div class="information">
		<h2 class="information__title">Информация</h2>
		<Accordion
			v-for="info in accordionItems"
			:key="info.title"
			:title="info.title"
			:description="info.description"
			:links="info.links"
			:contractLinks="info.contractLinks"
		/>
	</div>
</template>

<style lang="scss" scoped>
.information {
	padding: 58px 0 0;
	max-width: 1080px;
	margin: 0 auto;

	@media screen and (max-width: 1300px) {
		max-width: 620px;
	}
	@media screen and (max-width: 680px) {
		padding: 120px 0 0;
		max-width: 300px;
	}

	&__title {
		font-family: RF Dewi Expanded;
		font-size: 64px;
		font-weight: 900;
		line-height: 77px;
		text-transform: uppercase;

		@media screen and (max-width: 1300px) {
			font-size: 46px;
			line-height: 55px;
		}
		@media screen and (max-width: 680px) {
			font-size: 24px;
			line-height: 29px;
		}
	}
}
</style>
